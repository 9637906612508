<template>
    <div class="container">
        <div class="main">
            <div class="header">{{ nameScreen }}</div>
            <div class="backHome" @click="backClick">进入后台</div>
            <div class="mainCenter">
                <div class="ball">
                    <img src="@/assets/menu/yuan.png" alt="">
                    <div class="ballFont" @click="centerChange">
                      {{centerName}}
<!--
                        <img src="~@/assets/menu/names.png" alt="">
-->
                    </div>
                </div>
                <div class="left1">
                    <div class="ballBox" v-show="btn1 == 1" @click="boxChange1">
                        <div class="imgs1">
                            <img class="imgsSmall" src="@/assets/menu/ny.png" alt="">
                        </div>
                        <div class="imgsFont">数字农业大屏</div>
                    </div>
                    <div class="ballBox" v-show="btn2 == 1" @click="boxChange2">
                        <div class="imgs2">
                            <img class="imgsSmall" src="@/assets/menu/xczh.png" alt="">
                        </div>
                        <div class="imgsFont">库存管理大屏</div>
                    </div>
                </div>
                <div class="left2">
                    <div class="ballBox2" v-show="btn3 == 1" @click="boxChange3">
                        <div class="imgs3">
                            <img class="imgsSmall" src="@/assets/menu/jk.png" alt="">
                        </div>
                        <div class="imgsFont">实时监控大屏</div>
                    </div>
                    <div class="ballBox2" v-show="btn4 == 1" @click="boxChange4">
                        <div class="imgs4">
                            <img class="imgsSmall" src="@/assets/menu/gg.png" alt="">
                        </div>
                        <div class="imgsFont">远程控制大屏</div>
                    </div>
                </div>
                <div class="right1">
                    <div class="ballBox3" v-show="btn5 == 1" @click="boxChange5">
                        <div class="imgs5">
                            <img class="imgsSmall" src="@/assets/menu/sz.png" alt="">
                        </div>
                        <div class="imgsFont">无人机大屏</div>
                    </div>
                    <div class="ballBox3" v-show="btn6 == 1" @click="boxChange6">
                        <div class="imgs6">
                            <img class="imgsSmall" src="@/assets/menu/zh.png" alt="">
                        </div>
                        <div class="imgsFont">溯源管理大屏</div>
                    </div>
                </div>
                <div class="right2">
                    <div class="ballBox4" v-show="btn7 == 1" @click="boxChange7">
                        <div class="imgs7">
                            <img class="imgsSmall" src="@/assets/menu/wrj.png" alt="">
                        </div>
                        <div class="imgsFont">AI虫情识别</div>
                    </div>
                    <div class="ballBox4" v-show="btn8 == 1" @click="boxChange8">
                        <div class="imgs8">
                            <img class="imgsSmall" src="@/assets/menu/xc.png" alt="">
                        </div>
                        <div class="imgsFont">智慧党建</div>
                    </div>
                </div>
                <div class="bgBottom"></div>
                <div class="bottoms">
                    <div class="ballBox5" v-show="btn9 == 1" @click="boxChange9">
                        <div class="imgs9">
                            <img class="imgsSmall2" src="@/assets/menu/chong.png" alt="">
                        </div>
                        <div class="imgsFont">AI虫情</div>
                    </div>
                    <div class="ballBox5" v-show="btn10 == 1" @click="boxChange10">
                        <div class="imgs10">
                            <img class="imgsSmall2" src="@/assets/menu/su.png" alt="">
                        </div>
                        <div class="imgsFont">溯源大屏</div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
          nameScreen:"物联网综合系统",
          centerName:"综合大屏",
            btn1:0,
            btn2: 0,
            btn3: 0,
            btn4: 0,
            btn5: 0,
            btn6: 0,
            btn7: 0,
            btn8: 0,
            btn9: 0,
            btn10: 0,
        }
    },
    mounted() {
      this.getPowerName();
    },
    methods: {
      getPowerName() {
        this.$get("/largeScreenManagement/getLargeScreenManagements", {
          page: 1,
          size: 100,
        }).then((res) => {
          if (res.data.state == "success") {
            this.nameScreen = res.data.datas[0].name;
            let menus=JSON.parse(res.data.datas[0].conf1);
            console.log(menus);
            this.centerName=menus[0]||"综合大屏";
            this.btn1=menus[1]==0?0:1;
            this.btn2=menus[2]==0?0:1;
            this.btn3=menus[3]==0?0:1;
            this.btn4=menus[4]==0?0:1;
            this.btn5=menus[5]==0?0:1;
            this.btn6=menus[6]==0?0:1;
            this.btn7=menus[7]==0?0:1;
            this.btn8=menus[8]==0?0:1;
            this.btn9=menus[9]==0?0:1;
            this.btn10=menus[10]==0?0:1;
            this.resizeBigTitle();
          }
        });
      },
      resizeBigTitle(){
        let titLength = this.nameScreen.length;
        if (titLength<=8){
          this.bigTitleSize="40px";
        }else if (titLength<=10){
          this.bigTitleSize="35px";

        }else{
          this.bigTitleSize="22px";

        }
      },
        backClick(){
          this.$router.push("/backHome");
        },
        centerChange(){
          this.$router.push("/cloud");
        },
        boxChange1(){//农业综合大屏
          this.$router.push("/cloud");
        },
        boxChange2(){//冷库管理大屏
          this.$router.push("/coldStorage");
        },
        boxChange3(){//摄像头大屏
          this.$router.push("/monitScreen");
        },
        boxChange4(){//灌溉控制
          this.$router.push("/irrigateControl");
        },
        boxChange5(){//无人机大屏
          this.$router.push("/wrj");
        },
        boxChange6(){//溯源大屏
          this.$router.push("/agroTrace");
        },
        boxChange7(){//虫情识别
          this.$router.push("/insectScreen");
        },
        boxChange8(){//智慧党建
          this.$router.push("/partyBuilding");
        },
        boxChange9(){

        },
        boxChange10(){

        },
    }
}
</script>

<style scoped lang="less">

*{
    margin: 0;
    padding: 0;
}
.container{
    width: 100%;
    height: 100vh;
    color: #fff;
    background: url("~@/assets/menu/bg.png") no-repeat;
    background-size: 100% 100%;
}
.main{
    width: 100%;
    height: 100%;
    background: url("~@/assets/menu/bg2.png") no-repeat;
    background-size: 100% 100%;
}
.header{
    width: 100%;
    text-align: center;
    line-height: 10vh;
    font-size: 4vh;
    letter-spacing: 3px;
    font-family: 'jin';
}
.backHome {
    position: absolute;
    right: 3vw;
    top: 4vh;
    color: #fff;
    font-size: 2vh;
    z-index: 99;
    cursor: pointer;
    font-family: 'jin';
    color: #E3F6FF;
    text-shadow: 0px 3px 18px #020C1F;
}
.mainCenter{
    width: 95%;
    height: 83vh;
    margin: 1vh auto 0;
    position: relative;
}
.ball{
    width: 28vw;
    height: 53vh;
    margin: 7vh auto 0;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.ballFont{
    width: 100%;
    height: 15vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  line-height: 15vh;
  text-align: center;
  font-size:80px;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.left1, .left2, .right1, .right2{
    width: 37vw;
    height: 34vh;
    display: flex;
    justify-content: center;
    // align-items: center;
}
.left1{
    position: absolute;
    top: -9vh;
    left: 1vw;
    background: url("~@/assets/menu/zuo1.png") no-repeat;
    background-size: 100% 100%;
}
.left2{
    position: absolute;
    bottom: 21vh;
    left: 1vw;
    background: url("~@/assets/menu/zuo2.png") no-repeat;
    background-size: 100% 100%;
}
.right1{
    position: absolute;
    top: -9vh;
    right: 1vw;
    background: url("~@/assets/menu/you1.png") no-repeat;
    background-size: 100% 100%;
}
.right2{
    position: absolute;
    bottom: 21vh;
    right: 1vw;
    background: url("~@/assets/menu/you2.png") no-repeat;
    background-size: 100% 100%;
}
.ballBox{
    width: 9vw;
    height: 22vh;
    margin-right: 5vw;
    margin-top: 7vh;
    cursor: pointer;
}
.ballBox2{
    width: 9vw;
    height: 22vh;
    margin-right: 5vw;
    margin-top: 3vh;
    cursor: pointer;
}
.ballBox3{
    width: 9vw;
    height: 22vh;
    margin-left: 5vw;
    margin-top: 7vh;
    cursor: pointer;
}
.ballBox4{
    width: 9vw;
    height: 22vh;
    margin-left: 5vw;
    margin-top: 3vh;
    cursor: pointer;
}
.imgs1, .imgs2, .imgs3, .imgs4, .imgs5, .imgs6, .imgs7, .imgs8{
    width: 100%;
    height: 18vh;
    background: url("~@/assets/menu/di.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
}
.imgsSmall{
    width: 4vw;
    height: 8vh;
    margin-top: 1.5vh;
    object-fit: contain;
}

.imgsFont{
    line-height: 4vh;
    text-align: center;
    font-size: 2.5vh;
    letter-spacing: 2px;
    font-family: 'jin';
}
.bgBottom{
    width: 70vw;
    height: 22vh;
    position: absolute;
    bottom: 2vh;
    left: 50%;
    transform: translate(-50%);
    background: url("~@/assets/menu/bottom.png") no-repeat;
    background-size: 100% 100%;
}
.bottoms{
    position: absolute;
    bottom: 3vh;
    left: 50%;
    transform: translate(-50%);
    width: 32vw;
    height: 24vh;
    display: flex;
    justify-content: space-around;
}
.ballBox5{
    width: 9vw;
    height: 100%;
    cursor: pointer;
}
.imgs9, .imgs10{
    width: 100%;
    height: 20vh;
    background: url("~@/assets/menu/di2.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
}
.imgsSmall2{
    width: 5vw;
    height: 10vh;
    // margin-top: 1vh;
    object-fit: contain;
}

</style>